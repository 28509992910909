<template>
  <v-card :dark="$dark.get()">
    <loader v-if="loading" />
    <div class="custom-card">
      <div class="custom-card__title header">
        <span>Список поставщиков</span>
        <div class="custom-card__title_btns">
          <!-- поиск поставщиков -->
          <v-text-field
            v-if="!$vuetify.breakpoint.mdAndDown"
            v-model="findingProvider"
            label="Поиск..."
            class="custom-card__title_search"
            solo
            hide-details
            @keyup.enter="findProvider()"
            @input="checkText($event)"
          >
            <v-btn
              v-if="findingProvider"
              slot="append"
              color="red"
              icon
              @click="clearInput()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn slot="append" color="#5d4bd0" icon @click="findProvider()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-text-field>
          <v-btn color="success" rounded @click="redirectToLimits(0)">
            Лимиты Аяна
          </v-btn>
          <v-btn color="#5d4bd0" dark rounded @click="modal = true">
            Добавить
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="pl-0 custom-card_btn-block">
        <!-- поиск поставщиков -->
        <v-text-field
          v-if="$vuetify.breakpoint.mdAndDown"
          v-model="findingProvider"
          label="Поиск..."
          class="custom-card__title_search"
          solo
          hide-details
          @keyup.enter="findProvider()"
          @input="checkText($event)"
        >
          <v-btn
            v-if="findingProvider"
            slot="append"
            color="red"
            icon
            @click="clearInput()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn slot="append" color="#5d4bd0" icon @click="findProvider()">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-text-field>
        <v-btn text class="pl-0 custom-card_btn" to="/provider-groups"
          ><v-icon color="#5d4bd0" class="mr-2">mdi-plus-circle</v-icon
          >{{
            $vuetify.breakpoint.mdAndDown
              ? "Создать категорию"
              : "Создать новую категорию для структурирования поставщиков"
          }}
        </v-btn>
        <v-btn text class="pl-0 custom-card_btn" @click="downloadExel()"
          ><v-icon color="success" class="mr-2">mdi-tray-arrow-up</v-icon>
          {{
            $vuetify.breakpoint.mdAndDown
              ? "Выгрузить структуру"
              : "Выгрузить структуру и вложенность продавцов"
          }}
        </v-btn>
      </div>
      <div class="custom-card__container">
        <div v-if="!noFind" class="custom-table">
          <div class="head">
            <div
              v-for="item in items.head"
              :key="item.id"
              class="head__item"
              :class="item.id === 1 && 'name'"
            >
              {{ item.name }}
            </div>
          </div>
          <div v-for="(item, index) in items.body" :key="index">
            <v-list-group no-action color="#5d4bd0" :value="true">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }} ({{ item.providers.length }}
                    {{
                      $difEnd(
                        item.providers.length,
                        "компания",
                        "компании",
                        "компаний"
                      )
                    }})
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <!-- пути в подгруппах -->
              <v-list-item>
                <v-list-item-content>
                  <div
                    v-for="provider in item.providers"
                    :key="provider.id"
                    class="value"
                  >
                    <div class="value__item_name">
                      {{ provider.name }}
                    </div>
                    <div class="value__item">
                      <v-btn
                        icon
                        color="#5d4bd0"
                        @click="redirectToProfile(provider.id)"
                      >
                        <v-icon>mdi-arrow-right-circle</v-icon>
                      </v-btn>
                    </div>
                    <div class="value__item">
                      <v-btn
                        icon
                        color="#5d4bd0"
                        @click="redirectToProducts(provider.id)"
                      >
                        <v-icon dark>mdi-arrow-right-circle</v-icon>
                      </v-btn>
                    </div>
                    <div class="value__item">
                      <v-btn
                        icon
                        color="error"
                        @click="deleteProvider(provider.id)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </div>
        <p v-else>Поставщиков с таким именем не найдено</p>
      </div>
    </div>
    <!-- модалка для создание нового поставщика -->
    <v-dialog
      v-model="modal"
      activator="parent"
      :width="$vuetify.breakpoint.mdAndDown ? '95%' : '50%'"
    >
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title> Создание поставщика </v-card-title>
        <v-form ref="form" @submit.prevent="addNewProvider()">
          <v-text-field
            v-model.trim="newProvider.username"
            :rules="[$validate.required]"
            label="Имя поставщика"
            required
            clearable
          ></v-text-field>
          <v-text-field v-model="newProvider.email" label="Почта">
          </v-text-field>
          <v-text-field
            v-model="newProvider.password"
            label="Пароль"
            type="password"
            :rules="[$validate.required, $validate.passMin]"
          >
          </v-text-field>
          <v-select
            v-model="newProvider.departmentIds"
            label="Филиал"
            :items="PROVIDERS_LIST.departments"
            item-text="name"
            item-value="id"
            :rules="[$validate.arrRequired]"
            multiple
          >
          </v-select>
          <v-card-actions class="flex justify-end">
            <v-btn right @click="modal = false">Отмена</v-btn>
            <v-btn color="#5d4bd0" dark type="submit" right>Добавить</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <delete-dialog
      :show="show"
      @close-modal="show = !show"
      @delete-modal="deleteProvider()"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import loader from "@/components/Loader";
import DeleteDialog from "@/components/DeleteDialog";
import showMessage from "@/Functions/message";
import downloadFile from "@/Functions/downloadFile";
import api from "@/api";

export default {
  components: {
    loader,
    DeleteDialog,
  },
  data() {
    return {
      loading: false,
      modal: false,
      isRedacting: false,
      show: false,
      deletedName: "",
      findingProvider: "",
      noFind: "",
      newProvider: {
        username: "",
        departmentIds: [],
        password: "",
        email: "",
        roleId: 5,
      },
      items: {
        head: [
          { id: 1, name: "Имя поставщика" },
          { id: 4, name: "Профиль" },
          { id: 2, name: "Товары" },
          { id: 9, name: "Удалить" },
        ],
        body: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      STATE: "Providers/STATE",
      USER: "User/STATE",
      PROVIDERS_LIST: "ProvidersList/STATE",
    }),
  },
  watch: {
    // сбрасывает валидацию при закрытии окна
    modal(newVal) {
      if (!newVal) {
        this.newProvider.username = "";
        this.newProvider.password = "";
        this.newProvider.email = "";
        this.newProvider.departmentIds = [];
        this.$refs.form.resetValidation();
      }
    },
  },
  mounted() {
    this.GET_ALL_DEPARTMENTS();
    if (!this.STATE.allProviders.length) {
      this.setValues();
    } else {
      this.sortByGroups(this.STATE.allProviders);
    }
  },
  methods: {
    ...mapActions({
      getAllProviders: "Providers/GET_ALL_PROVIDERS",
      getProviderProducts: "Providers/GET_PROVIDER_PRODUCTS",
      deleteProviderAction: "Providers/DELETE_PROVIDER",
      addProvider: "Providers/ADD_PROVIDER",
      addUser: "User/ADD_USER",
      GET_ALL_DEPARTMENTS: "ProvidersList/GET_ALL_DEPARTMENTS",
    }),
    //Подгружает всех поставщиков
    async setValues() {
      this.loading = true;
      await this.getAllProviders();
      if (this.STATE.allProviders.length > 0) {
        this.sortByGroups(this.STATE.allProviders);
      } else {
        showMessage("Данные не загрузились");
      }
      this.loading = false;
    },
    // достает все возможные группы, и распределяет поставщиков по возможным группам
    sortByGroups(providersArr) {
      let groups = providersArr.map((provider) => provider.categoryOwnership);
      // формирует объект неповторяющихся групп
      groups = [...new Set(groups)].reduce((accum, item) => {
        if (item?.id) {
          accum[item.id] = { name: item.name, providers: [] };
        } else {
          accum[0] = { name: "Без группы", providers: [] };
        }
        return accum;
      }, {});
      // распределяет поставщиков по доступным группам
      providersArr.forEach((provider) => {
        if (provider.categoryOwnership) {
          groups[provider.categoryOwnership.id].providers.push(provider);
        } else {
          groups[0].providers.push(provider);
        }
      });
      this.items.body = groups;
    },
    async downloadExel() {
      this.loading = true;
      let file = await api.Providers.downloadStructureExel();
      this.loading = false;
      downloadFile(file);
    },
    //Добавляет нового поставщика
    async addNewProvider() {
      if (!this.$refs.form.validate()) {
        showMessage("Заполните все поля в форме");
        return;
      }
      this.loading = true;
      // сначала создается пользователь, а затем поставщик
      const responseUser = await this.addUser(this.newProvider);
      if (responseUser.type === "error") {
        showMessage(responseUser.text);
        this.loading = false;
        return;
      }
      const request = {
        name: responseUser.data.username,
        userId: responseUser.data.id,
      };
      const responseProvider = await this.addProvider(request);
      if (responseProvider.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Поставщик создан", true);
        await this.setValues();
        this.modal = false;
      }
      this.loading = false;
    },
    async deleteProvider(name) {
      if (name) {
        this.deletedName = name;
      } else {
        this.loading = true;
        let response = await this.deleteProviderAction(this.deletedName);
        if (response?.type === "error") {
          showMessage(response.data.message);
        } else {
          showMessage("Поставщик удален", true);
          await this.setValues();
        }
        this.deletedName = 0;
        this.loading = false;
      }
      this.show = !this.show;
    },
    async findProvider() {
      if (!this.findingProvider) {
        return;
      }
      const findArr = this.STATE.allProviders.filter((provider) =>
        provider.name.toLowerCase().includes(this.findingProvider.toLowerCase())
      );
      if (!findArr.length) {
        this.noFind = true;
      } else {
        this.noFind = false;
        this.sortByGroups(findArr);
      }
    },
    checkText(text) {
      if (!text) {
        this.clearInput();
      }
    },
    clearInput() {
      this.findingProvider = "";
      this.noFind = false;
      this.sortByGroups(this.STATE.allProviders);
    },
    redirectToProducts(id) {
      this.$router.push("providers-list/" + id);
    },
    redirectToLimits(id) {
      this.$router.push("providers-limit/" + id);
    },
    redirectToProfile(id) {
      this.$router.push("providers-profile/" + id);
    },
  },
};
</script>

<style lang="scss">
.custom-card {
  padding: 20px 5px;
  position: relative;
  &__title {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_btns {
      display: flex;
      align-items: center;
      gap: 10px;
      @media (max-width: 500px) {
        flex-direction: column;
      }
    }
    &_search {
      max-width: 250px;
      border-radius: 15px;
      max-height: 36px;
      .v-input__control {
        min-height: 36px !important;
        max-height: 36px !important;
      }
      @media (max-width: 500px) {
        min-width: 100%;
      }
    }
  }
  &_btn-block {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    @media (max-width: 500px) {
      flex-direction: column;
    }
  }
  &_btn {
    text-transform: none;
    font-weight: bolder !important;
  }
  .custom-card__container {
    .custom-table {
      .head {
        display: flex;
        justify-content: space-between;
        &__item {
          text-align: left;
          font-weight: 500;
          width: 12%;
          text-align: center;
          @media (max-width: 500px) {
            width: 18%;
          }
        }
      }
      .name {
        width: 40%;
        text-align: left;
      }
      .value {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        &:hover {
          background-color: #5d4bd013;
        }
        &__item {
          width: 12%;
          display: flex;
          align-items: center;
          justify-content: center;
          &_name {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: left;
            padding-left: 30px;
            @media (max-width: 500px) {
              padding-left: 0px;
            }
          }
        }
        &__btn {
          width: 100%;
        }
      }
      .v-list-item {
        padding-left: 0px !important;
      }
    }
  }
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 500px) {
    padding: 10px 5px;
  }
  &__text {
    padding: 0;
    margin: 0;
  }
}
.checkboxes {
  display: flex;
  gap: 20px;
}
.group-name {
  font-weight: bolder;
  margin-bottom: 0;
}
</style>
